.rs-input-group-inside {
  margin-top: 2px;

  border: var(--color2) !important;
}
.rs-picker-input-group:focus-within {
  border-color: var(--color2) !important;
  outline: none !important;
  width: 100px !important;
}
.rs-date-range-input.rs-input,
.rs-input-group.rs-input-group-inside {
  border: 0px !important;
  background-color: #09332c !important;
  color: #ffffff !important;
}
.rs-input-group-addon {
  display: none !important;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color1) !important;
  color: var(--color2) !important;
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: #efeac1 !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: var(--color2) !important;
}

.rs-btn-primary {
  background-color: var(--color1) !important;
  color: var(--color2) !important;
}
.rs-btn-link {
  color: var(--color2) !important;
}
.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 999 !important;
}
