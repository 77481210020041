:root {
  --color1: #ffe521;
  --color2: #007755;
  --color3: #a6bfa8;
  --color4: #09332c;
  --color5: #aec5b0;
  --bg-ltr: linear-gradient(90deg, #f3d80e 0%, #84be89 100%);
  --transitionDuration: 0.7s;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Sora" !important;
}

.custom-menu-drop .dropdown-item:hover {
  text-decoration: none !important;
  background-color: var(--color3);
}

.nav-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color1);
  padding: 0.5rem 0.5rem;
  transition: color var(--transitionDuration) ease,
    background-color var(--transitionDuration) ease,
    transform var(--transitionDuration) ease;
}

.nav-icon:hover {
  transform: scale(1.1);
}

.active .nav-icon {
  background-color: var(--color1);
  color: var(--color2);
  transform: scale(1);
  border-radius: 6px;
}

body {
  background-color: #f8f8f8 !important;
  font-family: "Sora" !important;
}

.fs-12 {
  font-size: 12px !important;
}

.wrap-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.progress-bar {
  background-color: var(--color1) !important;
  font-weight: 700;
  color: var(--color2) !important;
}

.loader-custom {
  color: var(--color1) !important;
}

.form-control:focus {
  border-color: var(--color2) !important;
  box-shadow: none !important;
}

.rs-picker-popup.rs-picker-popup-daterange {
  zoom: 80%;
}

.pdf-drop {
  background-image: url(assets/loginBg.png);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  max-width: 100vw;
  max-height: 100vh;
  opacity: 1 !important;
  overflow: hidden;
  pointer-events: auto;
  z-index: 1050;
}

.table-head,
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.table-wrapper {
  overflow-x: auto;
  position: relative;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  text-align: center;
}


.table-responsive::-webkit-scrollbar {
  height: 8px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: green;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.bar-chart-custom .recharts-cartesian-axis-tick-value {
  font-size: 16px !important;
  font-family: Sora !important;
  font-weight: 400 !important;
}

.mobilepad {
  padding-left: unset !important;
  padding-right: unset !important;
}

@media (max-width: 500px) {
  .rs-input {
    font-size: 10px !important;
  }
}

@media (max-width: 768px) {
  .mobilepad {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }

  .bottom-pos {
    bottom: -40px !important;
  }

  .position-card-tool {
    position: absolute !important;
    left: -20px !important;
  }

  .footer-bottom {
    margin-bottom: 3.6rem;
  }

  .text-small {
    font-size: 12px !important;
  }

  .table-head {
    font-size: 12px !important;
    color: #B6B6B6 !important;
    font-weight: 400 !important;
  }

  .sidebar-header {
    height: auto !important;
    margin: unset !important;
  }

  .switch-account {
    margin-right: -15px;
  }

  .rs-input {
    padding-right: 0px !important;
  }

  .pdf-drop {
    touch-action: none !important;
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    overflow-x: hidden !important;

  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sidebar-header {
  background-image: url(assets/green.png);
  background-size: cover;
  background-position: center;
  height: 250px;
  /* width: 100%; */
  overflow: hidden;
  zoom: 80%;
}

.position-card-tool {
  position: absolute !important;
  bottom: 1px;
}

.image-container {
  /* position: relative; */
  display: inline-block;
  /* This ensures that the container sizes properly */
}

.small-image {
  z-index: 5;
  height: 100px !important;
  transition: opacity 0.3s ease;
}

.large-image {
  background-color: white;
  position: fixed;
  top: 0;
  left: -25px;
  width: 200px;
  height: 200px;
  object-fit: contain;
  will-change: opacity, transform;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 10;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.image-container:hover .large-image {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}


.recharts-legend-item {
  font-size: 12px !important;
}

.pdf-size {
  width: 1655px !important;
  height: 1770px !important;
}

@media screen and (min-width: 1441px) {
  .metrics-pie-h {
    height: 550px;
  }

  .dashboard-page {
    margin-top: -70px;
    position: relative;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .metrics-pie-h {
    height: 550px;
  }

  .dashboard-page {
    margin-top: -70px;
    position: relative;
  }
}

.LoginScreen {
  background-image: url(assets/loginBg.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  overflow: hidden;
  height: 100%;
}

.sidebar:hover {
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.btn-custom {
  background-color: transparent !important;
  border-color: #aec5b0 !important;
  color: var(--color5) !important;
}

.btn-custom:hover {
  background-color: var(--color1) !important;
  border-color: var(--color2) !important;
  color: var(--color2) !important;
  transition: all 0.5s ease-in-out !important;
}

.bg-up {
  background-image: url(assets/Sticker.png);
  background-size: cover;
  background-position: center;
}

.bg-login {
  background: transparent url("assets/login.png") center / cover no-repeat !important;
  border-color: transparent !important;
  color: var(--color4) !important;
  padding: 18px !important;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}

.bg-login:hover {
  transform: rotate(2deg) scale(1.02);
}

.e-caret-hide::after {
  display: none !important;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, yellow, green);
  z-index: 9999;
  overflow: hidden;
}

.loading-container .spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255, 255, 255, 0.6);
  border-top-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-container .loading-text {
  margin-top: 20px;
  font-size: 24px;
  color: white;
  font-family: "Poppins", sans-serif;
}

.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 128, 0, 0.2);
}

.footer-custom {
  position: relative;
  bottom: 0;
  width: 100%;
}

.logo-footer {
  /* height: 30px; */
  width: 60px;
}

.bg-primary-ltr {
  background: var(--bg-ltr) !important;
}

.offcanvas {
  transition: transform 0.3s ease !important;
}

.offcanvas.show {
  transform: translateX(0) !important;
}

.offcanvas:not(.show) {
  transform: translateX(-100%) !important;
}

.leaflet-bottom {
  display: none !important;
}

.loader-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto 0;
  transform: translate(-50%, -50%);
}

.loader-small {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #09332C;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rs-picker-daterange-predefined {
  padding-top: 10px !important;
  padding-right: 10px !important;
  gap: 10px !important;
  /* background-color: #e0f7fa; */
}

.footer-custom {
  position: absolute;
  bottom: 0;
}

.custome-phone .invalid-feedback {
  display: block;
}

.custom-input .stdropdown-container {
  border: unset !important;
}

.custome-phone {
  width: 100% !important;
}

.custome-select-country input {
  font-size: 18px;
}

.custom-drop-down button {
  /* background-color: var(--base-color) !important; */
  background: var(--color1);
  color: var(--color2);
}

.custom-drop-down .dropdown-item {
  font-size: 18px;
  text-decoration: none;
}

.custom-input .stdropdown-input {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  /* display: block; */
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-color {
  --base-color: #fff510 !important;
  --highlight-color: #fff510 !important;
  background-color: #fff826 !important;
}

.company-select .form-select {
  background-color: var(--color1) !important;
  border: unset !important;
  color: var(--color2) !important;
}

.company-select .form-select option {
  background-color: white !important;
  color: initial;
}

.submit-btn-hover:hover {
  background: linear-gradient(90deg, #007755 0%, #55a785 100%) !important;
}

.company-btn-hover:hover {
  background: var(--color3) !important;
}

.gradient-select {
  background: var(--bg-ltr) !important;
  border: none !important;
  /* padding: 0.5rem 1rem !important; */
  border-radius: 0.375rem !important;
  /* appearance: none !important; */
  /* -webkit-appearance: none !important; */
}

.basic-multi-select .select__value-container {
  max-height: 67px;
  overflow-y: scroll;
}

.basic-multi-select .select__value-container::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.basic-multi-select .select__value-container::-webkit-scrollbar-thumb {
  background-color: var(--color2);
  border-radius: 10px;
}

.basic-multi-select .select__value-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color2);
}

.basic-multi-select .select__value-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.basic-multi-select .select__value-container::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}


.gradient-select:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem rgba(180, 190, 33, 0.5) !important;
  /* Optional focus effect */
}